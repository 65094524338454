import { createRouter, createWebHistory } from 'vue-router'
import DataEntryPage from '../views/DataEntryPage.vue'
import AnalysisPage from '../views/AnalysisPage.vue'
import LoginPage from '../views/LoginPage.vue'

const routes = [
  {
    path: '/login',
    name: 'LoginPage',
    component: LoginPage
  },
  {
    path: '/data-entry',
    name: 'DataEntry',
    component: DataEntryPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/analysis',
    name: 'Analysis',
    component: AnalysisPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/',
    redirect: '/analysis'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const isAuthenticated = localStorage.getItem('authenticated')
  
  if (to.meta.requiresAuth && !isAuthenticated) {
    next('/login')
  } else if (to.path === '/login' && isAuthenticated) {
    next('/')
  } else {
    next()
  }
})

export default router
