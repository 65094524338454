import axios from 'axios'

const API_URL = '/api'

export default {
  // Managers
  getManagers() {
    return axios.get(`${API_URL}/managers`)
  },
  
  addManager(name) {
    return axios.post(`${API_URL}/managers`, { name })
  },
  
  deleteManager(id) {
    return axios.delete(`${API_URL}/managers/${id}`)
  },

  // Data
  getData(year, month) {
    return axios.get(`${API_URL}/data`, {
      params: { year, month }
    })
  },
  
  addData(data) {
    return axios.post(`${API_URL}/data`, data)
  }
}
