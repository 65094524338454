<template>
  <v-app>
    <v-app-bar app color="primary" dark v-if="isAuthenticated">
      <v-btn 
        icon
        @click="$router.push('/analysis')"
        class="mr-4"
        :class="{ 'active-route': $route.path === '/analysis' }"
      >
        <v-icon>mdi-chart-bar</v-icon>
      </v-btn>
      <v-btn 
        icon
        @click="$router.push('/data-entry')"
        class="mr-4"
        :class="{ 'active-route': $route.path === '/data-entry' }"
      >
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
      <v-toolbar-title class="ml-4">
        {{ pageTitle }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn @click="logout">Выйти</v-btn>
    </v-app-bar>
    <v-main>
      <v-container>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  computed: {
    isAuthenticated() {
      return localStorage.getItem('authenticated')
    },
    pageTitle() {
      const titles = {
        '/analysis': 'Анализ показателей',
        '/data-entry': 'Ввод данных'
      }
      return titles[this.$route.path] || 'Dashboard'
    }
  },
  methods: {
    logout() {
      localStorage.removeItem('authenticated')
      this.$router.push('/login')
    }
  }
}
</script>

<style>
.active-route {
  background-color: rgba(255, 255, 255, 0.1);
}

.v-btn.active-route:hover {
  background-color: rgba(255, 255, 255, 0.2);
}
</style>
