<template>
  <div class="data-entry">
    <h1 class="mb-4">Ввод данных</h1>
    <v-card class="mb-4">
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6">
            <v-select
              v-model="selectedYear"
              :items="years"
              label="Год"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6">
            <v-select
              v-model="selectedMonth"
              :items="months"
              item-title="text"
              item-value="value"
              label="Месяц"
            ></v-select>
          </v-col>
        </v-row>
        
        <v-divider class="my-4"></v-divider>
        
        <v-row>
          <v-col cols="12" sm="6">
            <v-select
              v-model="formData.manager_id"
              :items="managers"
              item-title="name"
              item-value="id"
              label="Руководитель"
              required
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model.number="formData.salary"
              label="Зарплата"
              type="number"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
              v-model.number="formData.problem_employees"
              label="Проблемные сотрудники"
              type="number"
              min="0"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
              v-model.number="formData.stale_inventory"
              label="Количество залежей"
              type="number"
              min="0"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
              v-model.number="formData.promotional_inventory"
              label="Акционные залежи"
              type="number"
              min="0"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="text-center">
            <v-btn 
              color="primary" 
              size="large"
              :loading="loading"
              :disabled="!isFormValid"
              @click="saveData"
            >
              Сохранить
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import api from '@/services/api'

export default {
  name: 'DataEntryPage',
  data() {
    const currentYear = new Date().getFullYear()
    return {
      loading: false,
      selectedYear: currentYear,
      selectedMonth: new Date().getMonth() + 1,
      years: Array.from({length: 5}, (_, i) => currentYear - i),
      months: [
        { text: 'Январь', value: 1 },
        { text: 'Февраль', value: 2 },
        { text: 'Март', value: 3 },
        { text: 'Апрель', value: 4 },
        { text: 'Май', value: 5 },
        { text: 'Июнь', value: 6 },
        { text: 'Июль', value: 7 },
        { text: 'Август', value: 8 },
        { text: 'Сентябрь', value: 9 },
        { text: 'Октябрь', value: 10 },
        { text: 'Ноябрь', value: 11 },
        { text: 'Декабрь', value: 12 }
      ],
      managers: [],
      formData: {
        manager_id: null,
        salary: null,
        problem_employees: 0,
        stale_inventory: 0,
        promotional_inventory: 0
      }
    }
  },
  computed: {
    isFormValid() {
      return this.formData.manager_id && 
             this.formData.salary !== null &&
             this.formData.problem_employees >= 0 &&
             this.formData.stale_inventory >= 0 &&
             this.formData.promotional_inventory >= 0
    }
  },
  async created() {
    await this.loadManagers()
  },
  methods: {
    async loadManagers() {
      try {
        const response = await api.getManagers()
        this.managers = response.data
      } catch (error) {
        console.error('Ошибка загрузки руководителей:', error)
      }
    },
    async saveData() {
      if (!this.isFormValid) return
      
      this.loading = true
      try {
        const data = {
          ...this.formData,
          date: `${this.selectedYear}-${String(this.selectedMonth).padStart(2, '0')}-01`
        }
        
        await api.addData(data)
        
        // Очищаем форму
        this.formData = {
          manager_id: null,
          salary: null,
          problem_employees: 0,
          stale_inventory: 0,
          promotional_inventory: 0
        }
        
        // Показываем уведомление об успехе
        alert('Данные успешно сохранены')
      } catch (error) {
        console.error('Ошибка сохранения:', error)
        alert('Ошибка при сохранении данных')
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
