<template>
  <div class="analysis">
    <h1 class="mb-4">Анализ показателей</h1>
    <v-card class="mb-4">
      <v-card-title>
        Периоды для сравнения
        <v-btn
          color="primary"
          class="ml-4"
          @click="addPeriod"
          :disabled="periods.length >= 4"
        >
          Добавить период
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row 
          v-for="(period, index) in periods" 
          :key="index"
          align="center"
          class="mb-2"
        >
          <v-col cols="12" sm="5">
            <v-select
              v-model="period.year"
              :items="years"
              label="Год"
              @update:model-value="loadData"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="5">
            <v-select
              v-model="period.month"
              :items="months"
              item-title="text"
              item-value="value"
              label="Месяц"
              @update:model-value="loadData"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="2">
            <v-btn 
              icon
              color="error"
              @click="removePeriod(index)"
              v-if="periods.length > 1"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card>
      <v-card-text>
        <v-tabs v-model="activeTab" class="mb-4">
          <v-tab value="salary">Зарплаты</v-tab>
          <v-tab value="problems">Проблемные сотрудники</v-tab>
          <v-tab value="inventory">Залежи</v-tab>
          <v-tab value="promotional">Акционные залежи</v-tab>
        </v-tabs>
        
        <BarChart 
          v-if="chartData"
          :chartData="chartData"
          :options="chartOptions"
        />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import api from '@/services/api'
import BarChart from '@/components/BarChart.vue'

export default {
  name: 'AnalysisPage',
  components: {
    BarChart
  },
  data() {
    const currentYear = new Date().getFullYear()
    return {
      years: Array.from({length: 5}, (_, i) => currentYear - i),
      months: [
        { text: 'Январь', value: 1 },
        { text: 'Февраль', value: 2 },
        { text: 'Март', value: 3 },
        { text: 'Апрель', value: 4 },
        { text: 'Май', value: 5 },
        { text: 'Июнь', value: 6 },
        { text: 'Июль', value: 7 },
        { text: 'Август', value: 8 },
        { text: 'Сентябрь', value: 9 },
        { text: 'Октябрь', value: 10 },
        { text: 'Ноябрь', value: 11 },
        { text: 'Декабрь', value: 12 }
      ],
      periods: [
        {
          year: currentYear,
          month: new Date().getMonth() + 1
        }
      ],
      activeTab: 'salary',
      data: {},
      loading: false
    }
  },
  computed: {
    chartData() {
      if (!Object.keys(this.data).length) return null

      const datasets = this.periods.map(period => {
        const key = `${period.year}-${String(period.month).padStart(2, '0')}`
        const periodData = this.data[key] || []
        
        return {
          label: `${this.months.find(m => m.value === period.month).text} ${period.year}`,
          data: periodData.map(d => {
            switch (this.activeTab) {
              case 'salary': return d.salary
              case 'problems': return d.problem_employees
              case 'inventory': return d.stale_inventory
              case 'promotional': return d.promotional_inventory
              default: return 0
            }
          })
        }
      })

      const labels = this.getUniqueManagerNames()

      return {
        labels,
        datasets
      }
    },
    chartOptions() {
      return {
        responsive: true,
        scales: {
          y: {
            beginAtZero: true
          }
        }
      }
    }
  },
  methods: {
    addPeriod() {
      const lastPeriod = this.periods[this.periods.length - 1]
      let newMonth = lastPeriod.month - 1
      let newYear = lastPeriod.year

      if (newMonth < 1) {
        newMonth = 12
        newYear--
      }

      this.periods.push({
        year: newYear,
        month: newMonth
      })

      this.loadData()
    },
    removePeriod(index) {
      this.periods.splice(index, 1)
      this.loadData()
    },
    async loadData() {
      this.loading = true
      try {
        for (const period of this.periods) {
          const response = await api.getData(period.year, period.month)
          this.data[`${period.year}-${String(period.month).padStart(2, '0')}`] = response.data
        }
      } catch (error) {
        console.error('Ошибка загрузки данных:', error)
      } finally {
        this.loading = false
      }
    },
    getUniqueManagerNames() {
      const managers = new Set()
      Object.values(this.data).forEach(periodData => {
        periodData.forEach(d => {
          if (d.manager_name) {
            managers.add(d.manager_name)
          }
        })
      })
      return Array.from(managers)
    }
  },
  created() {
    this.loadData()
  }
}
</script>
